import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { FormatDateTime, Markdown } from '../index';
import {
  Actions,
  Container,
  Content,
  DatePlaceholder,
  MediaContainer,
  TemplateBody,
  Text,
  Title,
  BigIcon,
  NotificationImage,
} from './styles';
import { useLang } from '../../util/AppState';
import { _ } from '../../util/translate';
import { getButtons } from '../TopBar/Notifications/TopBarNotificationsOverlay';
import {
  getIconAndColor,
  optimisticMarkReadResponse,
  MappedBetaLink,
} from '../../util/NotificationsHelper';
import { StyledDiv } from '../../packages/Mailings';
import { WHITE } from '../../camtool-styles';

const getBackgroundImageByColor = (color) =>
  `linear-gradient(to right, ${color} 0, ${color} 8px, ${WHITE} 8px, ${WHITE} 100%)`;

const getBackgroundImage = (message) => {
  const isHighPrio =
    message.priority === 'HIGH' || message.priority === 'HIGHER' || message.priority === 'HIGHEST';
  return isHighPrio ? getBackgroundImageByColor('red') : 'none';
};

const NotificationContent = ({ notification, ...props }) => {
  const {
    id,
    showFrom,
    title,
    subtitle,
    onMarkRead,
    icon,
    picture,
    openNotification,
    onLinkClick,
    uri,
  } = notification;

  const [lang] = useLang();
  const imageSrc = picture?.src;
  const { iconType, color: iconColor } = getIconAndColor(icon?.src);

  return (
    <StyledDiv css={{ backgroundImage: getBackgroundImage(notification) }} {...props}>
      <MappedBetaLink
        link={uri}
        openNotification={openNotification}
        onLinkClick={onLinkClick}
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          color: 'unset',
          justifyContent: 'center',
        }}
      >
        <Container>
          {(icon || imageSrc) && (
            <MediaContainer>
              {imageSrc ? (
                <NotificationImage src={imageSrc} alt={`Picture of ${title}`} />
              ) : (
                <BigIcon>
                  <span className={iconType} style={{ color: iconColor }} />
                </BigIcon>
              )}
            </MediaContainer>
          )}

          <TemplateBody>
            <Content>
              <DatePlaceholder>
                <FormatDateTime value={showFrom} />
              </DatePlaceholder>
              <Title
                css={{
                  fontSize: '1.35rem',
                  marginTop: 16,
                  marginBottom: 0,
                }}
              >
                {title}
              </Title>
              <Text>
                <Markdown source={subtitle} />
              </Text>
            </Content>

            <Actions>
              {getButtons(notification)}
              {onMarkRead && (
                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline', alignSelf: 'center' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onMarkRead({
                      variables: { id },
                      optimisticResponse: optimisticMarkReadResponse(id),
                    });
                  }}
                >
                  {_('common:button.markRead')}
                </span>
              )}
            </Actions>
          </TemplateBody>
        </Container>
      </MappedBetaLink>
      {props.children}
    </StyledDiv>
  );
};

NotificationContent.propTypes = {
  notification: PropTypes.object.isRequired,
  children: PropTypes.node,
};

NotificationContent.defaultProps = {};

export default withRouter(NotificationContent);
