import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import * as Formsy from 'formsy-react';

import { Checkbox, Dropdown } from '../../../components';
import AbstractComponent from '../../../components/AbstractComponent';
import {
  PropFilter,
  STATUS_ACTIVE,
  STATUS_EXPIRED,
  STATUS_PLANNED,
  TYPE_PHOTO,
  TYPE_VIDEO,
} from './Props';
import { _ } from '../../../util/translate';

const options = [
  { value: STATUS_ACTIVE, label: 'marketing:campaigns.status.active' },
  { value: STATUS_EXPIRED, label: 'marketing:campaigns.status.expired' },
  { value: STATUS_PLANNED, label: 'marketing:campaigns.status.planned' },
];

const normalize = (filter) => {
  if (!filter.photo && !filter.video) {
    filter.photo = 1;
    filter.video = 1;
  }
  return {
    status: filter.status || 'active',
    photo: filter.photo ? 1 : 0,
    video: filter.video ? 1 : 0,
  };
};

const getFilterFromProps = (props) => {
  return {
    status: props.filter.status,
    video: props.filter.type.indexOf(TYPE_VIDEO) !== -1,
    photo: props.filter.type.indexOf(TYPE_PHOTO) !== -1,
  };
};

class CampaignFilter extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: normalize(getFilterFromProps(props)),
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextFilter = getFilterFromProps(nextProps);
    if (!isEqual(this.state.filter, nextFilter)) {
      this.setState({
        filter: normalize(nextFilter),
      });
    }
  }

  onChange(nextFilter) {
    const filter = normalize(nextFilter);
    if (!isEqual(this.state.filter, filter)) {
      this.setState({ filter }, () => {
        const type = [];
        if (this.state.filter.video === 1) type.push(TYPE_VIDEO);
        if (this.state.filter.photo === 1) type.push(TYPE_PHOTO);
        this.props.onChange({ status: filter.status, type });
      });
    }
  }

  render() {
    const { state } = this;
    return (
      <Formsy.Form className="grid__box__header__item" onChange={this.onChange}>
        <div className="marketing-campaigns__filter">
          <div className="marketing-campaigns__filter__item">
            <Checkbox name="photo" label={_('common:texts.photo')} value={state.filter.photo} />
          </div>
          <div className="marketing-campaigns__filter__item">
            <Checkbox name="video" label={_('common:texts.video')} value={state.filter.video} />
          </div>
          <div className="marketing-campaigns__filter__item">
            <Dropdown name="status" value={state.filter.status} forceTranslate options={options} />
          </div>
        </div>
      </Formsy.Form>
    );
  }
}

CampaignFilter.propTypes = {
  filter: PropFilter.isRequired,
  onChange: PropTypes.func.isRequired,
};

CampaignFilter.defaultProps = {};

export default CampaignFilter;
